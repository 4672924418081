import BackHead from '@/assets/invite/back_head.png';
import Back from '@/assets/invite/back.png';
import Word from '@/assets/invite/word.png';
import Share from '@/assets/invite/share.png';
import LinkQuan from '@/assets/invite/link_quan.png';
import FriendQuan from '@/assets/invite/friend_quan.png';
import PriceQuan from '@/assets/invite/price_quan.png';
import More from '@/assets/invite/more.png';
import InvBack from '@/assets/invite/inv_back.png';
import Close from '@/assets/invite/close.png';
import { topInvite } from '@/api/user';
import { computed, ref } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import store from '@/store';
export default {
  name: 'InviteHome',

  setup() {
    const show = ref(false);
    const yue = ref(false);
    const router = useRouter();
    const route = useRoute();

    if (route.query.channel !== undefined && route.query.channel !== null && route.query.channel === 'huawei') {
      yue.value = true;
    }

    const token = computed(() => {
      return store.getters.getToken;
    });
    const info = computed(() => {
      return store.getters.invInfo;
    });

    const back = () => {
      if (window.HybridAPI !== undefined) {
        const param = {
          method: 'closeWindow',
          params: {
            url: info.value.url
          },
          callback: ''
        };
        window.HybridAPI.sendToNative(JSON.stringify(param));
      } else {
        router.back();
      }
    };

    const showTips = () => {
      show.value = true;
    };

    const closeTip = () => {
      show.value = false;
    };

    const link = () => {
      if (window.HybridAPI !== undefined) {
        const param = {
          method: 'shareLink',
          params: {
            url: info.value.url
          },
          callback: ''
        };
        window.HybridAPI.sendToNative(JSON.stringify(param));
      }
    };

    const haibao = () => {
      if (window.HybridAPI !== undefined) {
        const param = {
          method: 'shareHaibao',
          params: {
            url: info.value.url
          },
          callback: ''
        };
        window.HybridAPI.sendToNative(JSON.stringify(param));
      }
    };

    const list = ref([]);

    if (window.HybridAPI !== undefined) {
      if (token.value !== '') {
        topInvite().then(res => {
          if (res.code === 0) {
            list.value = res.data;
          }
        });
      } else {
        const inter = setInterval(() => {
          if (token.value !== '') {
            topInvite().then(res => {
              if (res.code === 0) {
                list.value = res.data;
              }
            }).finally(() => clearInterval(inter));
          } else {
            clearInterval(inter);
          }
        }, 2000);
      }
    }

    return {
      BackHead,
      Back,
      back,
      showTips,
      Word,
      Share,
      LinkQuan,
      FriendQuan,
      PriceQuan,
      More,
      InvBack,
      list,
      link,
      haibao,
      Close,
      closeTip,
      show,
      router,
      info,
      yue
    };
  }

};