import request from '@/utils/request'
import { escalation } from '@/utils/request'

const api = {
  DownPageRecord: 'game-api/index/downPageRecord',
  PunishList: 'web-api/index/punishList',
  GuideInfo: 'web-api/index/platformGuideInfo',
  CollectInfo: '/web-api/index/collectInfo'
}

export function downPageRecord () {
  return escalation({
    url: api.DownPageRecord + '/' + 1,
    method: 'post'
  })
}

export function punishList () {
  return request({
    url: api.PunishList,
    method: 'get'
  })
}

export function guideInfo (id) {
  return request({
    url: api.GuideInfo + '/' + id,
    method: 'get'
  })
}

export function collectInfo (param) {
  return request({
    url: api.CollectInfo,
    method: 'get',
    params: param
  })
}
