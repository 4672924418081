import { useRouter } from 'vue-router';
import Back from '@/assets/invite/back.png';
export default {
  name: 'ShareUserAgreement',

  setup() {
    const router = useRouter();
    return {
      router,
      Back
    };
  }

};