import { createStore } from 'vuex'
import { getInviteInfo } from '@/api/user'

export default createStore({
  state: {
    invInfo: {},
    token: ''
  },
  getters: {
    invInfo: state => state.invInfo,
    getToken: state => state.token
  },
  mutations: {
    SET_INV: (state, info) => {
      state.invInfo = info
    },
    SET_TOKEN: (state, data) => {
      state.token = data
    }
  },
  actions: {
    SaveToken ({ commit }, token) {
      return new Promise((resolve) => {
        commit('SET_TOKEN', token)
        resolve()
      })
    },
    RefreshInv ({ commit }) {
      return new Promise((resolve) => {
        getInviteInfo().then(res => {
          if (res.code === 0) {
            commit('SET_INV', res.data)
          }
        })
        resolve()
      })
    }
  },
  modules: {
  }
})
