import Back from '@/assets/invite/back.png';
import { useRouter } from 'vue-router';
import { onMounted, ref } from 'vue';
import { inviteList } from '@/api/user';
export default {
  name: 'InviteRecord',

  setup() {
    document.getElementById('viewport').content = 'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0;';
    const router = useRouter();
    const list = ref([]);
    const refreshing = ref(false);
    const loading = ref(false);
    const finished = ref(false);
    onMounted(() => {
      inviteList(param.value).then(res => {
        if (res.code === 0) {
          list.value = res.data.list;
        }

        if (res.data.list.length === 0 || res.data.pageSize < param.value.pageSize) {
          finished.value = true;
        }
      });
    });

    const onLoad = () => {
      loading.value = true;
      param.value.pageNo++;
      inviteList(param.value).then(res => {
        if (res.code === 0) {
          list.value = [...list.value, ...res.data.list];
        }

        if (res.data.list.length === 0 || res.data.pageSize < param.value.pageSize) {
          finished.value = true;
        }
      }).finally(() => loading.value = false);
    };

    const param = ref({
      pageNo: 1,
      pageSize: 20
    });

    const onRefresh = () => {
      refreshing.value = false;
      param.value.pageNo = 1;
      inviteList(param.value).then(res => {
        if (res.code === 0) {
          list.value = res.data.list;
        }
      }).finally(() => refreshing.value = false);
    };

    const curentTime = time => {
      const date = new Date(time);
      const year = date.getFullYear(); //年

      const month = date.getMonth() + 1; //月

      const day = date.getDate(); //日
      // var hh = now.getHours();            //时
      // var mm = now.getMinutes();          //分
      //
      // var clock = year + "-";
      //
      // if(month < 10)
      //   clock += "0";
      //
      // clock += month + "-";
      //
      // if(day < 10)
      //   clock += "0";
      //
      // clock += day + " ";
      //
      // if(hh < 10)
      //   clock += "0";
      //
      // clock += hh + ":";
      // if (mm < 10) clock += '0';
      // clock += mm;

      return year + '年' + month + '月' + day + '日';
    };

    return {
      router,
      Back,
      list,
      curentTime,
      refreshing,
      onRefresh,
      loading,
      onLoad,
      finished
    };
  }

};