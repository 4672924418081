import Left from '@/assets/left.png';
export default {
  name: 'TopTitle',
  props: {
    title: {
      type: String,
      default: ''
    }
  },
  components: {},

  setup(props, context) {
    const back = () => {
      context.emit('back');
    };

    return {
      back,
      Left
    };
  }

};