import { ref } from 'vue';
import Left from '@/assets/left.png';
export default {
  name: 'TimeSelect',
  props: {
    title: {
      type: String,
      default: ''
    }
  },
  components: {},

  setup(props, context) {
    const sel = ref(0);

    const change = () => {
      context.emit('changeVal', sel.value);
    };

    const back = () => {
      context.emit('back');
    };

    return {
      back,
      Left,
      sel,
      change
    };
  }

};