import Right from '@/assets/right.png';
import { useRouter } from 'vue-router';
import TopTitle from '@/components/TopTitle.vue';
export default {
  name: 'DeviceAndroid',
  components: {
    TopTitle
  },

  setup() {
    const router = useRouter();

    const back = () => {
      router.back();
    };

    return {
      Right,
      back,
      router
    };
  }

};