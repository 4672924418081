import store from '@/store';
import { nextTick } from 'vue'; // @ts-ignore
// import VConsole from 'vconsole/dist/vconsole.min'
// const vConsole = new VConsole()

export default {
  name: 'InviteBase',

  setup() {
    document.getElementById('viewport').content = 'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0;';
    nextTick(() => {
      if (window.HybridAPI !== undefined) {
        window.HybridAPI.onReceiveUser = msg => {
          const data = JSON.parse(msg);
          store.dispatch('SaveToken', data.token).then(() => {
            store.dispatch('RefreshInv');
          });
        };

        const param = {
          method: 'getUserInfo',
          params: {},
          callback: 'onReceiveUser'
        };
        window.HybridAPI.sendToNative(JSON.stringify(param));
      }
    });
    return {// vConsole
    };
  }

};