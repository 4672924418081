import Right from '@/assets/right.png';
import { onMounted, ref } from 'vue';
import { useRouter } from 'vue-router';
import TopTitle from '@/components/TopTitle.vue';
import TimeSelect from '@/components/TimeSelect.vue';
import BottomTip from '@/components/BottomTip.vue';
import { collectInfo } from '@/api';
export default {
  name: 'UserStudy',
  components: {
    BottomTip,
    TimeSelect,
    TopTitle
  },

  setup() {
    const router = useRouter();
    const info = ref({
      count: 0,
      content: ''
    });

    const back = () => {
      router.back();
    };

    const change = val => {
      getData(val);
    };

    const getData = time => {
      const param = {
        type: 17,
        time: time
      };
      collectInfo(param).then(res => {
        if (res.code === 0) {
          info.value = res.data;
        }
      });
    };

    onMounted(() => {
      getData(0);
    });
    return {
      Right,
      back,
      router,
      info,
      change
    };
  }

};