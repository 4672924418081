import request from '@/utils/request'

const api = {
  Logon: 'web-api/user/login',
  Logout: 'dragon/logout',
  GetUserInfo: 'web-api/user/getUserInfo',
  GetUserList: 'web-api/user/getUserList',
  TopInvite: 'web-api/user/top-invite',
  InviteList: 'web-api/user/invite-list',
  SendCash: 'web-api/user/send-cash',
  GetInviteInfo: 'web-api/user/get-invite-info',
  CashRecord: 'web-api/user/cash-record'

}

export function getUserInfo () {
  return request({
    url: api.GetUserInfo,
    method: 'get',
  })
}

export function inviteList (param) {
  return request({
    url: api.InviteList,
    method: 'get',
    params: param
  })
}

export function cashRecord (param) {
  return request({
    url: api.CashRecord,
    method: 'get',
    params: param
  })
}

export function topInvite () {
  return request({
    url: api.TopInvite,
    method: 'get'
  })
}

export function getInviteInfo () {
  return request({
    url: api.GetInviteInfo,
    method: 'get'
  })
}

export function sendCash (data) {
  return request({
    url: api.SendCash,
    method: 'post',
    data: data
  })
}
